@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  transition: all 300ms ease-in-out;
  scroll-behavior: smooth;
  font-family: "Lato", sans-serif;
}
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 4px;
  color: var(--green-2);
}
*::-webkit-scrollbar-thumb {
  border: 5px solid var(--green-2);
  border-radius: 4px;
  background: transparent;
}

:root {
  --green-1: #0b574f;
  --green-2: #005a34;
  --green-3: #115d40;
  --green-shadow: 5px 5px 13px #042320, -5px -5px 13px #042320;
  --light-green: #16a085;
  --dark: #111111;
  --dark-shadow-out: 0.3rem 0.3rem 0.6rem #c8d0e7,
    -0.2rem -0.2rem 0.5rem #ffffff;
  --dark-shadow-in: inset 0.2rem 0.2rem 0.5rem #c8d0e7,
    inset -0.2rem -0.2rem 0.5rem #ffffff;
  --drop-shadow: 2px 2px 1px var(--green-3);
  --light: #e4ebf5;
}

body {
  background-color: var(--light);
  color: var(--dark);
}

.img {
  & > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}
table,
th,
td {
  text-align: center;
}
table {
  background-color: var(--dark);
}
th,
td {
  background-color: var(--light);
  padding: 8px;
  color: var(--dark);
}

input {
  accent-color: var(--green-2);
}
button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}
.field {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  font-size: 1.2rem;
  & > label {
    white-space: nowrap;
  }
  & > input,
  & > select {
    &.small {
      width: min(400px, 100%);
    }
    padding: 6px 12px;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 1.2rem;
    background-color: transparent;
    box-shadow: var(--dark-shadow-out);
    color: var(--dark);
    & > option {
      background: var(--light);
      color: var(--dark);
    }
    &:focus {
      box-shadow: var(--dark-shadow-in);
    }
  }
}
.d {
  &-flex {
    display: flex;
  }
  &-inline-flex {
    display: inline-flex;
  }
}
.flex {
  &-row {
    flex-direction: row;
  }
  &-column {
    flex-direction: column;
  }
}
.align {
  &-items {
    &-center {
      align-items: center;
    }
  }
}

.text {
  &-left {
    text-align: left;
  }
  &-wrap {
    white-space: normal;
  }
  &-nowrap {
    white-space: nowrap;
  }
}
